<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side">
        <div v-if="isMobile && !isHeaven && !formOnly" class="d-flex justify-content-center">
          <!-- Logo -->
          <div class="logo-banner">
            <img v-if="!isHeaven" src="@/assets/logo.png" />
            <img v-else src="@/assets/img/icons-heaven/logo.svg" />
          </div>
        </div>
        <!-- Banner -->
        <div v-if="isMobile && !isHeaven && !formOnly" class="d-flex justify-content-center">
          <div>
            <img src="@/assets/img/banner/topo_cadastro_.png" />
          </div>
        </div>

        <div :class="{
          'inner-wrapper' : !formOnly,
          'inner-wrapper-iframe': formOnly
        }" data-anima="top">
          <div v-if="!isMobile || (isMobile && isHeaven)" class="d-flex justify-content-between">
            <!-- Logo -->
            <span class="logo">
              <img v-if="!isHeaven" src="@/assets/logo.png" />
              <img v-else src="@/assets/img/icons-heaven/logo.svg" />
            </span>
            <div>
              <Locales :positionAbsolute="false"/>
            </div>
          </div>

          <b-form @submit.prevent="onSubmit" novalidate>
            <!-- Name -->
            <b-form-group
              :label="$t('views.seller.register.text_1433')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :placeholder="$t('views.seller.register.text_1312')"
                v-model.trim="name"
                @input="resetValidator()"
                @blur="formatName(name)"
                type="text"
                name="name"
                class="radius-form-control"
                v-validate="{ required: true, alpha_spaces: true }"
                :class="{'heaven':isHeaven}"
                :disabled="$route.query.name ? true : false"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('name')">
                {{ $t('views.seller.my_account.text_1161') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- E-mail -->
            <b-form-group
              :label="isClient ? $t('views.seller.register.text_1436_1') : $t('views.seller.register.text_1436')"
              label-for="email"
              class="position-relative"
            >
              <b-form-input
                id="email"
                :placeholder="$t('views.seller.register.text_1437')"
                v-model="email"
                @input="resetValidator"
                type="email"
                name="email"
                v-validate="'required|email'"
                :class="{'heaven':isHeaven}"
                :disabled="$route.query.email ? true : false"
                @keyup.down.prevent="increaseEmailIndex"
                @keyup.up.prevent="decreaseEmailIndex"
                @keyup.enter.prevent="selectEmailIndex"
              ></b-form-input>
              <div class="email-suggestions" v-show="filteredEmails.length">
                <div v-for="item, index in filteredEmails" @mousedown="email = item" :class="selectedEmailIndex == index && !isMobile ? 'selected-email' : ''">
                  {{ item }}
                </div>
              </div>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('email') : null"
              >
                {{ $t("views.seller.register.text_1322") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Phone -->
            <b-form-group v-if="!isClient"
              :label="$t('views.seller.register.text_1434')"
              label-for="phone"
            >
              <VuePhoneNumberInput
                v-model="whatsapp"
                :translations="$i18n.locale === 'pt' ? translations_pt : (
                  $i18n.locale === 'es' ? translations_es : translations_en
                )"
                error-color="#ff0c37"
                size="lg"
                :default-country-code="$i18n.locale === 'pt' ? 'BR' : (
                  $i18n.locale === 'es' ? 'ES' : (
                    $i18n.locale === 'en' ? 'US' : 
                    (isHeaven ? 'US' : 'BR')
                  )
                )"
                :preferred-countries="isHeaven ? ['US', 'BR', 'PT', 'FR', 'ES'] : ['BR', 'PT', 'US', 'FR', 'ES']"
                ref="telinput"
                :required="true"
                :countries-height="50"
                :border-radius="5"
                :class="{'heaven': isHeaven}"
                @update="onUpdate"
              />
              <b-form-invalid-feedback :state="submit ? results_tel.isValid : null">
                {{ $t("views.seller.register.text_1315") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Password -->
            <b-form-group
              :label="$t('views.seller.register.text_1323')"
              label-for="password"
            >
            <b-input-group>
              <b-form-input
                autocomplete="off"
                ref="password"
                id="password"
                :placeholder="showPassword ? $t('views.seller.register.put_a_password') : '••••••••'"
                v-model="password"
                type="password"
                name="password"
                v-validate="'strong_password'"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <span v-if="showPassword" style="position: absolute; right: 16px; top: 16px; cursor: pointer; z-index: 999" @click="mostrarSenha">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.78 11.78C10.6922 12.6092 9.36761 13.0685 8 13.0909C3.54545 13.0909 1 8 1 8C1.79157 6.52484 2.88945 5.23602 4.22 4.22M6.66364 3.06182C7.10167 2.95929 7.55013 2.90803 8 2.90909C12.4545 2.90909 15 8 15 8C14.6137 8.72266 14.153 9.40301 13.6255 10.03M9.34909 9.34909C9.17431 9.53666 8.96355 9.6871 8.72937 9.79144C8.49519 9.89578 8.24239 9.95189 7.98606 9.95641C7.72972 9.96094 7.4751 9.91378 7.23739 9.81777C6.99967 9.72175 6.78373 9.57884 6.60245 9.39755C6.42116 9.21627 6.27825 9.00033 6.18223 8.76261C6.08622 8.5249 6.03906 8.27028 6.04358 8.01394C6.04811 7.75761 6.10421 7.50481 6.20856 7.27063C6.3129 7.03645 6.46334 6.82569 6.65091 6.65091M1 1L15 15" stroke="#81858E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <span v-else style="position: absolute; right: 16px; top: 16px; cursor: pointer; z-index: 999" @click="mostrarSenha">
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 6.09091C1 6.09091 3.54545 1 8 1C12.4545 1 15 6.09091 15 6.09091C15 6.09091 12.4545 11.1818 8 11.1818C3.54545 11.1818 1 6.09091 1 6.09091Z" stroke="#81858E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 8C9.05436 8 9.90909 7.14527 9.90909 6.09091C9.90909 5.03655 9.05436 4.18182 8 4.18182C6.94564 4.18182 6.09091 5.03655 6.09091 6.09091C6.09091 7.14527 6.94564 8 8 8Z" stroke="#81858E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </b-input-group>
              <b-form-text>
                <password-validation :password="password"></password-validation>
              </b-form-text>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('password') : null"
              >
                {{ $t("views.seller.register.text_1324") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Use Terms -->
            <b-form-group
              label=""
              label-for="accepted_terms"
              class="termos-uso"
            >
              <b-form-checkbox
                id="accepted_terms"
                v-model="accepted_terms"
                name="accepted_terms"
                v-validate="'required'"
              >
                <span class="have-account">
                  {{ $t("i_agree_the") }}
                  <a
                    :href="isHeaven
                      ? 'https://heaven.me/heaven-termos-de-uso/'
                      : 'https://greenn.com.br/politicas-e-termos-greenn'
                      "
                    target="_blank"
                    class="signin"
                    >
                    {{ $t("terms_of_use_and_policies") }},
                  </a>
                  {{ $t("i_have_science_of") }}
                  <a
                    :href="isHeaven
                      ? 'https://heaven.me/heaven-termos-de-uso/'
                      : 'https://greenn.com.br/politica-de-privacidade-greenn/'
                      "
                    target="_blank"
                    class="signin"
                    >
                    {{ $t("privacy_police") }}
                  </a>
                  {{
                    isHeaven
                      ? $t("views.seller.register.text_1439_1")
                      : $t("views.seller.register.text_1439")
                  }}
                  {{ $t("agree_text_1") }}
                </span>
              </b-form-checkbox>
            </b-form-group>
            <!-- Creat Account Button -->
            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block col-12"
              >
                <Captcha
                  ref="Captcha"
                  v-if="accepted_terms && nameIsValid && (phoneIsValid || isClient) && emailIsValid && captchaIsLiberated"
                  @changeVerify="changeVerify"
                >
                  <!-- Create Button -->
                  <BaseButton
                    :class="{'heaven':isHeaven}"
                    variant="primary"
                    ref="button"
                    type="submit"
                    class="btn-submit mb-3"
                    :disabled="isHeaven"
                    @click="onSubmit"
                  >
                    {{ $t("signup_right_now") }}
                  </BaseButton>
                </Captcha>
                  <!-- Create Button -->
                  <BaseButton
                    v-else-if="accepted_terms && nameIsValid && (phoneIsValid || isClient) && emailIsValid"
                    :class="{'heaven':isHeaven}"
                    variant="primary"
                    ref="button"
                    :disabled="isHeaven"
                    type="submit"
                    class="btn-submit mb-3"
                    @click="changeVerify"
                  >
                    {{ $t("signup_right_now") }}
                  </BaseButton>
                <!-- Disabled Button -->
                <BaseButton
                  v-else
                  variant="primary"
                  ref="button"
                  disabled
                  type="submit"
                  class="btn-submit mb-3"
                >
                  {{ $t("signup_right_now") }}
                </BaseButton>
                <div v-if="!formOnly" class="d-flex" style="width: 100%; justify-content: center;"> 
                  <span class="have-account">{{ $t("do_you_already_have_a_account") }}</span> 
                  <span style="cursor: pointer;" @click="$router.push('/')" class="ml-1 have-account signin"> {{ $t("signin") }} </span>
                </div>
                <div v-else class="d-flex" style="width: 100%; justify-content: center;"> 
                  <span class="have-account">{{ $t("do_you_already_have_a_account") }}</span> 
                  <span style="cursor: pointer;" @click="goToLoginBlank" class="ml-1 have-account signin"> {{ $t("signin") }} </span>
                </div>
              </b-overlay>
            </b-row>
          </b-form>
        </div>
      </b-col>

      <b-col v-if="!formOnly" cols="12" md="6" class="right-side" :class="{'heaven': isHeaven}"> </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { EventBus } from "@/main.js"

import Captcha from '@/components/security/Captcha.vue'
import PasswordValidation from '@/components/shared/PasswordValidation.vue'
import crisp from '@/mixins/crisp.js'
import Cookies from 'js-cookie'
import Locales from '@/components/Locales.vue'
import VuePhoneNumberInput from "vue-phone-number-input";

import UserSettingsService from '@/services/resources/UserSettingsService'
import ClientTypeRegisterService from '@/services/resources/ClientTypeRegisterService'
import SellerRegisterService from '@/services/resources/SellerRegisterService'
import MetaService from '@/services/resources/MetaService'
import GlobalSettings from "@/services/resources/GlobalSettings";

const serviceUserSettings = UserSettingsService.build()
const serviceClientTypeRegister = ClientTypeRegisterService.build()
const service = SellerRegisterService.build()
const serviceMeta = MetaService.build()
const serviceGlobalSettings = GlobalSettings.build();

export default {
  name: 'Register',
  components: {
    Captcha,
    PasswordValidation,
    Locales,
    VuePhoneNumberInput
  },
  data() {
    return {
      results_tel: {
        isValid: false,
      },
      locale: "BR",
      showNameValid: false,
      submit: false,
      showPassword: false,
      name: '',
      email: '',
      password: '',
      confirm_password: '',
      confirm_password_type: 'password',
      document_type: '',
      cpf: '',
      cnpj: '',
      phone: '',
      country_phone: '',
      accepted_terms: false,
      captchaIsLiberated: false,
      success: true,
      loading: false,
      hash: null,
      id_product: null,
      id_indicacao: null,
      seller_type: null,
      navigator: navigator,
      translations_pt: {
        countrySelectorLabel: "Código do país",
        countrySelectorError: "Escolha um país",
        phoneNumberLabel: "Número de Telefone",
        example: "Exemplo: ",
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: ",
      },
      translations_es: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo: ",
      },
      whatsapp: '',
      utms:{
        utm_source:"",
        utm_medium:"",
        utm_campaign:"",
        utm_term:"",
        utm_content:"",
      },
      fb: null,
      fbq: undefined,
      client: {
        width: 0,
      },
      selectedEmailIndex: 0,
    }
  },
  mixins: [crisp],
  computed: {
    isClient() {
      return this.$store.getters["getIsClient"]
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    nameIsValid() {
      return !this.showNameValid
    },
    emailIsValid() {
      return this.email.length > 0
    },
    phoneIsValid() {
      return this.whatsapp?.length && this.results_tel?.isValid;
    },
    isMobile() {
      return this.client.width <= 768;
    },
    formOnly() {
      return this.$route.query.formOnly ? true : false
    },
    filteredEmails() {
      this.selectedEmailIndex = 0
      if (!this.email.includes('@') || this.email.endsWith('.com')) {
        return []
      }

      const emailSplitted = this.email.split('@')

      const suggestions = [
        "@gmail.com",
        "@icloud.com",
        "@hotmail.com",
        "@outlook.com",
        "@yahoo.com"
      ]
      if (!emailSplitted[1]) {
        return suggestions.map(item => emailSplitted[0] + item)
      }
      return suggestions.filter(item => item.includes(emailSplitted[1])).map(item => emailSplitted[0] + item)
    },
    userLogged() {
      return this.$store.getters.getProfile;
    },
  },
  watch: {
    name(){
      const allValidatedName = this.nameValidationSteps(this.name);
      const isAllValidatedName = allValidatedName.every((step) => step.validated === true);
      if (!isAllValidatedName) {
        this.showNameValid = true;
        return;
      }
      this.showNameValid = false;
    },
  },
  methods: {
    nameValidationSteps(name){
      const validations_name = [
        { step: this.$t('views.seller.validation_account_type.text_2'), validated: /[A-Za-z]\s[A-Za-z]/.test(name) },
      ];
      return validations_name ;
    },
    fetchGlobalSettings() {
      serviceGlobalSettings
        .search({ keys: "REGISTER_CAPTCHA" })
        .then((response) => {
          response.forEach((item) => {
            if (item.key == "REGISTER_CAPTCHA") {
              this.captchaIsLiberated = item.value === "1" || item.value === 1|| item.value === 'ENABLED' || item.value === 'enabled';
            }
          });
        })
        .catch(err => console.error(err));
    },
    changeVerify(e) {
      if (this.captchaIsLiberated) {
        this.captcha = e
        this.onSubmit('get')
      } else {
        this.onSubmit('get')
      }
    },
    async addMeta() {
      let data = {
        meta_key: 'onboarding_step',
        meta_value: 'welcome'
      }

      await serviceMeta.create({ ...data, id: this.userLogged.id }, undefined, '/create-or-update');
    },
    mostrarSenha() {
      const input = document.querySelector('#password')
      this.showPassword = !this.showPassword
      if(this.showPassword) {
        input.setAttribute('type', 'text')
        // this.$refs.mostrar_senha.innerText = this.$t('views.seller.register.text_1451')
      } else {
        input.setAttribute('type', 'password')
        // this.$refs.mostrar_senha.innerText = this.$t('views.seller.register.text_1438')
      }
    },
    resetValidator() {
      this.$validator.reset()
    },
    async updateTermsOfUse() {
      let data = {
        key: 'USE_TERMS_CELCOIN',
        value: 'ACCEPTED'
      }

      await serviceUserSettings.create(data, '/terms-of-use-celcoin')
        .then(() => {})
        .catch(err => console.error(err))
    },
    onSubmit(get) {
      if(this.showNameValid || !this.name){
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1569_2'), {
          title: this.$t('views.seller.my_account.text_1198'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
        return
      }

      if(get !== 'get') {
        if (this.captchaIsLiberated) {
          this.$refs.Captcha.setCaptcha()
          return
        }
      }
      this.submit = true
      
      this.$validator.validateAll().then((result) => {
        if(result) {
          fbq('trackCustom', 'Cadastro Greenn')

          this.loading = true

          let data = {
            social_name: this.name,
            name: this.name,
            email: this.email,
            password: this.password,
            cellphone: this.phone,
            country_phone: this.country_phone,
            document_type: this.isClient
              ? ''
              : this.$i18n.locale === 'pt' ? 'CPF' : this.document_type.toUpperCase(),
            terms: this.accepted_terms,
            captcha: this.captcha,
            type: this.isClient ? 'CLIENT' : 'SELLER',
            utm_source : this.utms.utm_source,
            utm_medium: this.utms.utm_medium,
            utm_campaign: this.utms.utm_campaign,
            utm_term: this.utms.utm_term,
            utm_content: this.utms.utm_content,
            fb: this.fb
          }

          if(this.id_indicacao) data.confio = this.id_indicacao

          if(this.isClient) {
            serviceClientTypeRegister
            .create(data)
            .then(({ access_token, token_type }) => {
              const bearer_access_token = `${token_type} ${access_token}`
              Cookies.set('access_token', bearer_access_token, {
                expires: 365
              })
              this.$store.commit('authSuccess', bearer_access_token)
              this.$router.push({ path: `/dashboard${window.location.search}`, query: { type: 'client' } })
              // this.updateTermsOfUse()
            })
            .catch((err) => {
              this.$refs.Captcha.resetCaptcha()
              if (
                err.response.status == 422 &&
                err.response.data &&
                err.response.data.email.length
              ) {
                const h = this.$createElement
                const a = h(
                  'a',
                  {
                    on: {
                      click: (e) => {
                        e.preventDefault()
                        this.$router.push({
                          path: '/',
                          query: { email: this.email },
                        })
                      }
                    },
                    attrs: { href: '/' }
                  },
                  this.$t('views.seller.register.text_1452')
                )

                this.$bvToast.toast([a], {
                  title: this.$t('views.seller.register.text_1453'),
                  variant: 'info',
                  noAutoHide: true,
                  appendToast: true
                })
              }
            })
            .finally(() => { this.loading = false })
          } else {
            service
              .create(data, this.$route.query.indicated_by ? '?indicated_by='+this.$route.query.indicated_by : '')
              .then(({ access_token, token_type }) => {
                const bearer_access_token = `${token_type || 'Bearer'} ${access_token}`
                Cookies.set('access_token', bearer_access_token, {
                  expires: 1
                })
                this.$store.commit('authSuccess', bearer_access_token)
  
                if(this.hash) {
                  this.$router.push(`/recrutamento/${this.hash}/?p_id=${this.id_product}&manager=${this.manager}&confirmOpen=open`)
                } else {
                  if (!this.formOnly) {
                    this.addMeta()
                    this.$router.push(`/boas-vindas?step=welcome`)
                  } else {
                    const message = {
                      action: 'redirect-greenn',
                      url: `${process.env.VUE_ADM_PAGE}/boas-vindas?step=welcome&token=${bearer_access_token}`
                    };
                    window.parent.postMessage(JSON.stringify(message), '*');
                  }
                }
              })
              .catch((err) => {
                this.$refs.Captcha.resetCaptcha()
                if (
                  err.response.status == 422 &&
                  err.response.data &&
                  err.response.data.email.length
                ) {
                  const h = this.$createElement
                  const a = h(
                    'a',
                    {
                      on: {
                        click: (e) => {
                          e.preventDefault()
                          this.$router.push({
                            path: '/',
                            query: { email: this.email },
                          })
                        }
                      },
                      attrs: { href: '/' }
                    },
                    this.$t('views.seller.register.text_1452')
                  )
  
                  this.$bvToast.toast([a], {
                    title: this.$t('views.seller.register.text_1453'),
                    variant: 'info',
                    noAutoHide: true,
                    appendToast: true
                  })
                }
              })
              .finally(() => { this.loading = false })
          }
        } else {
          this.$bvToast.toast([], {
            title: this.$t('views.seller.register.text_1453_1'),
            variant: 'danger',
            noAutoHide: false,
            appendToast: true
          })
        }
      })
      .catch(e => {
        console.log("eeee", e)
      })
    },
    formatName(text) {
      if (!text) {
        return;
      }
      text = text.replace(/\d/g, ''); // Apenas letras
      text = text.replace(/\s+/g, ' ').trim(); // Sem espaços duplos
      text = text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()); // Iniciais em maiúsculo
      this.name = text

      if (text.split(' ').length <= 1) {
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1569_2'), {
          title: this.$t('views.seller.my_account.text_1198'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
      }
    },
    installTags() {
      // tags google
      let googleScript = document.createElement('script')
      googleScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=AW-11221195320')
      googleScript.async = true
      document.head.appendChild(googleScript)

      window.dataLayer = window.dataLayer || []
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date())

      gtag('config', 'AW-11221195320');

      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TRVTZVCJ')
      `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TRVTZVCJ"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.prepend(noscript);

      // fim tags google

      // ------

      // inicio pixel tiktok
      !(function (w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = (w[t] = w[t] || []);
        (ttq.methods = [
          'page',
          'track',
          'identify',
          'instances',
          'debug',
          'on',
          'off',
          'once',
          'ready',
          'alias',
          'group',
          'enableCookie',
          'disableCookie',
        ]),
          (ttq.setAndDefer = function (t, e) {
            t[e] = function () {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          });
        for (var i = 0; i < ttq.methods.length; i++)
          ttq.setAndDefer(ttq, ttq.methods[i]);
        (ttq.instance = function (t) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
            ttq.setAndDefer(e, ttq.methods[n]);
          return e;
        }),
          (ttq.load = function (e, n) {
            var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
            (ttq._i = ttq._i || {}),
              (ttq._i[e] = []),
              (ttq._i[e]._u = i),
              (ttq._t = ttq._t || {}),
              (ttq._t[e] = +new Date()),
              (ttq._o = ttq._o || {}),
              (ttq._o[e] = n || {});
            var o = document.createElement('script');
            (o.type = 'text/javascript'),
              (o.async = !0),
              (o.src = i + '?sdkid=' + e + '&lib=' + t);
            var a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(o, a);
          });

        ttq.load('C5O60ULO3VNUQLVLNK00');
        ttq.page();
      })(window, document, 'ttq');

      // fim pixel tiktok
    },
    onUpdate(payload) {
      this.results_tel = payload;
      if(payload.phoneNumber) {
        this.phone = `+${payload.countryCallingCode+payload.phoneNumber.replace(' ', '').replace('-','').replace('(','').replace(')','')}`
        this.country_phone = payload.countryCode
      }
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    goToLoginBlank() {
      window.open(process.env.VUE_ADM_PAGE, '_blank')
    },
    decreaseEmailIndex() {
      if (this.selectedEmailIndex > 0) {
        --this.selectedEmailIndex
      }
    },
    increaseEmailIndex() {
      if (this.filteredEmails.length > this.selectedEmailIndex + 1) {
        ++this.selectedEmailIndex
      }
    },
    selectEmailIndex() {
      let newEmail = this.filteredEmails[this.selectedEmailIndex]
      this.email = newEmail ?? this.email
    },
  },
  mounted() {
    // add script
    this.installTags()
    if (!this.formOnly) {
      this.initCrisp()
    }
    // fim crisp

    if(this.$route.query.hash_a) this.hash = this.$route.query.hash_a
    if(this.$route.query.p_id) this.id_product = this.$route.query.p_id
    if(this.$route.query.confio) this.id_indicacao = +this.$route.query.confio

    if(this.$route.query.name) this.name = this.$route.query.name
    if(this.$route.query.email) this.email = this.$route.query.email
    if(this.$route.query.utm_source) this.utms.utm_source = this.$route.query.utm_source
    if(this.$route.query.utm_medium) this.utms.utm_medium = this.$route.query.utm_medium
    if(this.$route.query.utm_campaign) this.utms.utm_campaign = this.$route.query.utm_campaign
    if(this.$route.query.utm_term) this.utms.utm_term = this.$route.query.utm_term
    if(this.$route.query.utm_content) this.utms.utm_content = this.$route.query.utm_content
    if(this.$route.query.fb) this.fb = this.$route.query.fb

    EventBus.$on('atualizaPais', (data) => {
      this.locale = data.locale;
    });
  },
  created() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize();
    this.fetchGlobalSettings();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
}
</script>

<style scoped lang="scss">
.email-suggestions {
  position: absolute;
  z-index: 1000;
  background-color: white;
  width: 100%;
  border: 1px solid #ededf0;
  border-radius: 0 0 10px 10px;
  display: none;
  &:not(:hover) > .selected-email{
    background-color: var(--gray-50);
  }
  > div {
    padding: 10px;
    border-bottom: 1px solid #ededf0;
    transition: .3s;
    cursor: pointer;
    &:hover {
      background-color: var(--gray-50);
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

input:focus + .email-suggestions {
  display: block;
}
.logo-banner {
  width: 100px;
  height: auto;
  margin-bottom: 8px;
  margin-top: 8px;
}
</style>

<style lang="scss">
.country-selector__toggle {
  padding-top: 5px !important;
  margin-right: 14px;
}
.country-selector.lg {
  margin-right: 14px;
}
#MazPhoneNumberInput .select-country-container{
  z-index: 999 !important;
}
#MazPhoneNumberInput-20_phone_number {
  border-radius: 5px !important;
}

.country-selector__input, .input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}

.country-selector__label, .input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #333333 !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}
</style>

<style lang="scss" scoped>
.bg {
  background: $base-color;
}
.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}

.right-side {
  background-image: url("~@/assets/img/cadastro-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.right-side.heaven {
  background: radial-gradient(circle, $base-color-heaven-light 32%, $base-color-heaven 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.right-side.heaven::after {
  content: url("~@/assets/img/icons-heaven/logo.svg");
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.left-side {
  background: #fff;
  padding: 0 !important;
  height: 100%;

  .inner-wrapper {
    margin-top: 20px;
    padding: 1% 18%;
    .logo {
      img {
        width: 100px;
        height: auto;
        margin-bottom: 30px;
      }
    }
  }
  .inner-wrapper-iframe {
    margin-top: 12px;
    padding: 5% 8%;
    padding-bottom: 30px;
    .logo {
      img {
        width: 100px;
        height: auto;
        margin-bottom: 30px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .inner-wrapper form {
    padding-bottom: 30px;
  }
  .inner-wrapper {
    padding: 6% 11% !important;
  }
  .grecaptcha-badge {
    right: -194px !important;
  }
}
.btn-submit {
  width: 100%;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}

@media screen and (max-height: 946px) {
  .full-height {
    height: 100% !important;
  }
}
.termos-uso {
  margin-left: 25px;
}
.text-termos {
  cursor: pointer !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.text-termos a {
  color: #2133d2;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
.radius-form-control {
  border-radius: 10px !important;
}
.vue-phone-number-input.lg .select-country-container {
  padding-right: 14px !important;
}
#password {
  font-size: 14px !important;
}
.have-account {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(129, 133, 142, 1);
}
.signin {
  color: rgba(0, 32, 146, 1) !important;
}
</style>
